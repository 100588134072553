import styled from 'styled-components'

import { _XS_Normal, mainColors, zIndex } from '@avantstay/backoffice-core'

const HEADER_CELL_WIDTH = 36
const CELL_HEIGHT = 34
const BLOCK_HEIGHT = 8
const CELL_PADDING = 8
const CELL_HORIZONTAL_PADDING = 6

export const CalendarContainer = styled.div<{ $columnsLength: number }>`
  display: grid;
  grid-template-columns: ${({ $columnsLength }) => `max-content repeat(${$columnsLength}, 1fr)`};
  border: 1px solid ${mainColors.neutralMedium};
  border-radius: 4px;
  width: 100%;
`

export const HeaderCell = styled.div`
  text-align: center;
  ${_XS_Normal};
  min-width: ${HEADER_CELL_WIDTH}px;
  padding: ${CELL_PADDING}px;
  border-bottom: 1px solid ${mainColors.neutralMedium};
  border-right: 1px solid ${mainColors.neutralMedium};
  background-color: ${mainColors.neutralLight};
`

export const RowContainer = styled.div`
  display: contents;
`

export const RowTitle = styled.div`
  text-align: center;
  ${_XS_Normal};
  height: ${CELL_HEIGHT}px;
  padding: ${CELL_PADDING}px ${CELL_HORIZONTAL_PADDING}px;
  border-right: 1px solid ${mainColors.neutralMedium};
  border-bottom: 1px solid ${mainColors.neutralMedium};
  background-color: ${mainColors.neutralLight};
`

export const DayCell = styled.div`
  position: relative;
  border-bottom: 1px solid ${mainColors.neutralMedium};
  border-right: 1px solid ${mainColors.neutralMedium};
  height: ${CELL_HEIGHT}px;
`

export const Block = styled.div<{ $blockSpan: number; color: string }>`
  position: absolute;
  top: ${(CELL_HEIGHT - BLOCK_HEIGHT) / 2}px;
  left: 0;
  width: ${({ $blockSpan }) => $blockSpan * 100}%;
  height: ${BLOCK_HEIGHT}px;
  background-color: ${({ color }) => color};
  border-radius: 22px;
  z-index: ${zIndex.TABLE_CONTENT};
`
