import React, { Ref } from 'react'

function _IconDoor(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.21986 6.21122V17.4993H15.7799V6.21122H8.21986ZM6.77986 6.0701V17.4993H5.69986C5.30221 17.4993 4.97986 17.8152 4.97986 18.2049C4.97986 18.5945 5.30221 18.9104 5.69986 18.9104H7.45526C7.47001 18.9113 7.48488 18.9118 7.49986 18.9118H16.4999C16.5148 18.9118 16.5297 18.9113 16.5445 18.9104H18.2999C18.6975 18.9104 19.0199 18.5945 19.0199 18.2049C19.0199 17.8152 18.6975 17.4993 18.2999 17.4993H17.2199V6.0701C17.2199 5.3418 16.6009 4.80005 15.8999 4.80005H8.09986C7.39883 4.80005 6.77986 5.3418 6.77986 6.0701ZM14.0996 12.7281C14.5966 12.7281 14.9996 12.3332 14.9996 11.8461C14.9996 11.359 14.5966 10.9641 14.0996 10.9641C13.6025 10.9641 13.1996 11.359 13.1996 11.8461C13.1996 12.3332 13.6025 12.7281 14.0996 12.7281Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const IconDoor = React.forwardRef(_IconDoor)
