import { datadogRum } from '@datadog/browser-rum'
import { segment } from '@tracking/segmentInitializer'
import { IS_LOCALHOST, IS_PRODUCTION } from '@utils/constants'

/*
 * Here we report errors and events to Datadog and Segment.
 * We can skip sending data to segment if necessary (Technical Monitoring or Testing Purposes)
 * To skip it, just pass skipSendToSegment = false in helper param
 * By default, the helper will always send it to Datadog and Segment, it is not possible to skip Datadog due to business rules.
 */

const failedTrackerMessage = 'Error creating tracker, see data to get more details'

export const trackError = (
  error: string,
  comment: Record<string, unknown> | undefined | string,
  skipSendToSegment?: boolean,
): void => {
  if (!error) return

  const parsedCommentError = typeof comment === 'string' ? { info: comment } : { ...comment }

  const errorToLog = new Error(error)
  errorToLog.name = error
  errorToLog.cause = parsedCommentError

  datadogRum.addError(errorToLog)

  if (skipSendToSegment !== false && IS_LOCALHOST === false) {
    /**
     * For dev environments we add the host to be able to identify the host
     * This is so we can know if it's on voyage.avantstay.dev or a staging link for an MR
     */
    const currentErrorComment = IS_PRODUCTION
      ? parsedCommentError
      : { ...parsedCommentError, host: window.location.host }

    segment
      .track(error, currentErrorComment)
      .then(res => (!res ? segment.track(failedTrackerMessage, error) : null))
      .catch(err =>
        segment.track(failedTrackerMessage, {
          origin: error,
          trace: err,
        }),
      )
  }
}

export const trackEvent = (
  actionName: string,
  metadata: Record<string, unknown>,
  skipSendToSegment?: boolean,
): void => {
  datadogRum.addAction(actionName, metadata)

  if (skipSendToSegment !== false && IS_LOCALHOST === false) {
    /**
     * For dev environments we add the host to be able to identify the host
     * This is so we can know if it's on voyage.avantstay.dev or a staging link for an MR
     */
    const currentMetaData = IS_PRODUCTION ? metadata : { ...metadata, host: window.location.host }

    segment
      .track(actionName, currentMetaData)
      .then(res => (!res ? segment.track(failedTrackerMessage, actionName) : null))
      .catch(err =>
        segment.track(failedTrackerMessage, {
          origin: actionName,
          trace: err,
        }),
      )
  }
}
