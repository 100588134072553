import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { safeLazy } from '@avantstay/backoffice-core'

import { NewVersionNotification } from '@new/__global/components/NewVersionNotification'

import { ROUTES } from '@routes'
import history from '@utils/history'
import { withErrorBoundary } from '@utils/withErrorBoundary'

const AccountingScreen = withErrorBoundary(safeLazy(() => import('@screens/Accounting.screen')))
const Dashboard = withErrorBoundary(safeLazy(() => import('@screens/Dashboard.screen')))
const Properties = withErrorBoundary(safeLazy(() => import('@screens/Properties.screen')))
const Property = withErrorBoundary(safeLazy(() => import('@screens/Property.screen')))
const Hotel = withErrorBoundary(safeLazy(() => import('@screens/Hotel.screen')))
const Bookings = withErrorBoundary(safeLazy(() => import('@screens/Bookings.screen')))
const Booking = withErrorBoundary(safeLazy(() => import('@screens/Booking.screen')))
const Settings = withErrorBoundary(safeLazy(() => import('@screens/Settings.screen')))
const Marketing = withErrorBoundary(safeLazy(() => import('@screens/Marketing.screen')))
const Operations = withErrorBoundary(safeLazy(() => import('@screens/Operations.screen')))
const Markets = withErrorBoundary(safeLazy(() => import('@screens/Markets.screen')))
const ErrorScreen = safeLazy(() => import('@screens/Error.screen'))
const RatingsScreen = withErrorBoundary(safeLazy(() => import('@/screens/Ratings.screen')))
const ScheduledSms = withErrorBoundary(safeLazy(() => import('@/screens/ScheduledSms.screen')))
const PeopleScreen = withErrorBoundary(safeLazy(() => import('@screens/People.screen')))
const FinanceScreen = React.lazy(() => import('@screens/Finance.screen'))

const isPathnameRegion = history.location.pathname.includes('/region')
const isPathnameRegions = history.location.pathname.includes('/regions')

export const MainScreensSwitch = () => {
  return (
    <>
      <Switch>
        <Route {...ROUTES.finance} component={AccountingScreen} />
        <Route {...ROUTES.dashboard} component={Dashboard} />
        <Route {...ROUTES.properties} component={Properties} />
        <Route {...ROUTES.property} component={Property} />
        <Route {...ROUTES.hotel} component={Hotel} />
        <Route {...ROUTES.hotelTapeChart} component={Bookings} />
        <Route {...ROUTES.fastTapeChart} component={Bookings} />
        <Route {...ROUTES.bookings} component={Bookings} />
        <Route {...ROUTES.accountingResolutionCenter} component={FinanceScreen} />
        <Route {...ROUTES.servicesRequests} component={Bookings} />
        <Route {...ROUTES.idVerification} component={Bookings} />
        <Route {...ROUTES.refundRequests} component={Bookings} />
        <Route {...ROUTES.tapeChart} component={Bookings} />
        <Route {...ROUTES.bookingConflicts} component={Bookings} />
        <Route {...ROUTES.marketing} component={Marketing} />
        <Route {...ROUTES.settings} component={Settings} />
        <Route {...ROUTES.booking} component={Booking} />
        <Route {...ROUTES.error} component={ErrorScreen} />
        <Route {...ROUTES.people} component={PeopleScreen} />
        <Route {...ROUTES.revenue} component={FinanceScreen} />
        <Route {...ROUTES.revenueMapping} component={FinanceScreen} />
        <Route {...ROUTES.fees} component={FinanceScreen} />
        <Route {...ROUTES.pricingTapeChart} component={FinanceScreen} />
        <Route {...ROUTES.revenueBookingsTapeChart} component={FinanceScreen} />
        <Route {...ROUTES.revenueSettings} component={FinanceScreen} />
        <Route {...ROUTES.seasonGroups} component={FinanceScreen} />
        <Route {...ROUTES.seasons} component={FinanceScreen} />
        <Route {...ROUTES.yieldMaps} component={FinanceScreen} />
        <Route {...ROUTES.yieldGroups} component={FinanceScreen} />
        <Route {...ROUTES.discounts} component={FinanceScreen} />
        <Route {...ROUTES.markups} component={FinanceScreen} />
        <Route {...ROUTES.ownerIncomeDashboard} component={FinanceScreen} />
        <Route {...ROUTES.pricingTool} component={FinanceScreen} />
        <Route {...ROUTES.ratings} component={RatingsScreen} />
        <Route {...ROUTES.cancelScheduledSms} component={ScheduledSms} />
        <Route {...ROUTES.operations} component={Operations} />
        <Route {...ROUTES.markets} component={Markets} />
        <Redirect from={ROUTES.reports.path} to={ROUTES.ratingsSuperhost.path} />

        {/* Support old url */}
        {!isPathnameRegions && !isPathnameRegion && (
          <Redirect from="*" to={ROUTES.dashboard.path} />
        )}
        <Redirect from="/region/:id" to={ROUTES.ratingsMarketsReview.path} />
        <Redirect from="/guests" to={ROUTES.guests.path} />
      </Switch>
      <NewVersionNotification />
    </>
  )
}
