import styled from 'styled-components'

import { _M_Medium, _XS_Normal, mainColors } from '@avantstay/backoffice-core'

import medias from '@styles/medias'
import { zIndex } from '@styles/zIndex'

export const Root = styled.div`
  display: flex;
  position: relative;
  z-index: ${zIndex.NEW_VERSION_NOTIFICATION_MODAL};
  border-radius: 16px;
  border: 1px solid ${mainColors.neutralMedium};
  box-shadow: 0 4px 50px 0 rgba(2, 43, 84, 0.1);
  padding: 16px;
  background-color: ${mainColors.primaryMedium};
  gap: 16px;
  width: 100%;
  max-width: 830px;
  align-items: center;
  flex-direction: column;

  button {
    width: unset;
  }

  ${medias.MD_SCREEN`
    padding: 12px 20px;
    flex-direction: row;
  `}
`

export const IconPlusText = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const Icon = styled.div`
  display: flex;
  flex-basis: 70px;
  margin: -8px;
  flex-shrink: 0;
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
`
export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  width: 100%;

  ${medias.MD_SCREEN`
    display: flex;
    flex-direction: row;
    width: unset;

    button {
      color: ${mainColors.white};
    }
  `}
`

export const Title = styled.div`
  display: flex;
  ${_M_Medium};
  color: ${mainColors.white};
`

export const Info = styled.div`
  display: flex;
  ${_XS_Normal};
  color: ${mainColors.white};
`
