import { useEffect, useState } from 'react'

import { IS_PRODUCTION, IS_STAGING } from '@utils/constants'

import { NewVersionNotificationModal } from './NewVersionNotificationModal'

const SOCKET_URL = process.env.REACT_APP_TABLEAU_NEW_VERSION_BROADCAST_WEBSOCKET
const VERBOSE = false
const CURRENT_VERSION = '4191f1e2232590cd3a795337b1bfa0326590c087' // this value will be replaced on CI build
const CHECKS_MIN_INTERVAL = 10 * 1000 // 10s

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let setIntervalId: any
let lastVersionCheck: number

const initWebSocket = (setNotificationVisible: VoidFunction) => {
  if (!SOCKET_URL || !(IS_PRODUCTION || IS_STAGING)) {
    return // localhost or MR url
  }

  if (VERBOSE) {
    console.log('WebSocket init')
  }

  const socket = new WebSocket(SOCKET_URL)

  const sendVersionRequest = () => {
    const sinceLastCheck = Date.now() - (lastVersionCheck || 0)
    if (sinceLastCheck > CHECKS_MIN_INTERVAL && socket?.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ action: 'version' }))
    }
  }

  // this is useful on leaving the browser tab and returning to it
  // it's also covering laptop lid close and reopen scenario
  document.addEventListener('visibilitychange', () => {
    if (VERBOSE) {
      console.log(`visibilitychange event, hidden: ${document.hidden}`)
    }
    if (!document.hidden) {
      sendVersionRequest()
    }
  })

  socket.onopen = function (event) {
    if (VERBOSE) {
      console.log('WebSocket connection established!')
    }
    sendVersionRequest()
  }

  socket.onmessage = function (event) {
    if (VERBOSE) {
      console.log('Message received from server:', event.data)
    }
    // {"version":"07579a935c671d380f340ae4f4b298a3bfada151","time":"2025-03-10 11:01:02 UTC"}
    const { version } = JSON.parse(event.data)

    if (version) {
      lastVersionCheck = Date.now()
      if (CURRENT_VERSION !== version) {
        // alertAboutNewAppVersion()
        setNotificationVisible()
      }
    }
  }

  socket.onerror = function (error) {
    if (VERBOSE) {
      console.error('New version check webSocket error occurred:', error)
    }
  }

  socket.onclose = function (event) {
    if (VERBOSE) {
      if (event.wasClean) {
        console.log(
          `WebSocket connection closed cleanly, code=${event.code}, reason=${event.reason}`,
        )
      } else {
        console.warn('WebSocket connection was unexpectedly closed')
      }
    }
    initWebSocket(setNotificationVisible)
  }

  if (setIntervalId) {
    clearInterval(setIntervalId)
  }

  // keep alive
  setIntervalId = setInterval(() => {
    if (VERBOSE) {
      console.log('WebSocket keep alive', socket.readyState)
    }
    if (socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify({ action: 'ping' }))
    } else if (socket.readyState === WebSocket.CLOSED) {
      initWebSocket(setNotificationVisible)
    }
  }, 45000) // 45 seconds
}

// This hook provides notification to the active users about new Voyage version deployment to suggest them
// to refresh the page to load latest assets. Using previous version might cause dynamically loaded modules to fail.
// Every build is getting unique postfixes on module files (e.g. Settings.screen-8ab04e82.js)
// This solution is using AWS infra for the backend part:
// - AWS API Gateway WebSocket to communicate with Voyage instances and broadcast new version event
// - AWS Lambda functions to collect GitLab pipeline events and broadcast messages to Voyage instances
// - AWS DynamoDB table to store connectionIds
// Solution works for staging & production envs (skipped for localhost and MR dedicated urls)
// GitLab Webhooks are sending pipeline events for backoffice project to AWS Lambda. Lambda function is broadcasting
// new version event to active Voyage instances over WebSocket.

export const useNewVersionEvents = () => {
  const [notificationVisible, setNotificationVisible] = useState(false)

  useEffect(() => {
    initWebSocket(() => setNotificationVisible(true))
  }, [])

  return {
    newVersionNotification: notificationVisible ? (
      <NewVersionNotificationModal onDismiss={() => setNotificationVisible(false)} />
    ) : null,
  }
}
