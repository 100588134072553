import styled from 'styled-components'

import { _M_Medium, _M_Normal, mainColors } from '../../../styles'
import { mobileMediaQuery } from '../../../utils'

interface ReviewCardContainerProps {
  isLastReview?: boolean
  isLastReviewOnPage?: boolean
}

export const ReviewCardContainer = styled.div<ReviewCardContainerProps>`
  width: 100%;
  max-width: 700px;
  padding: 18px 0;

  ${({ isLastReview, isLastReviewOnPage }) =>
    !isLastReviewOnPage &&
    `
  border-bottom: 1px solid;
  border-color: ${isLastReview ? mainColors.neutralDark : mainColors.neutralMedium};
  padding-bottom: ${isLastReview ? 40 : 20}px;
`}
`

export const ReviewBreakdownContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;

  ${mobileMediaQuery`
    flex-flow: column;
    align-items: flex-start;
    gap: 10px;
  `}
`

export const OverallContainer = styled.div``

export const DetailedRatingsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px 30px;
  flex-grow: 1;
`

export const ReviewRatingLabel = styled.p`
  ${_M_Medium};
`

export const ReviewCommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 20px;
`

export const ReviewCommentHeader = styled.p`
  ${_M_Medium};
  margin-bottom: 2px;
`

export const ReviewComment = styled.p`
  ${_M_Normal};
  color: ${mainColors.neutralExtremelyDark};
`
