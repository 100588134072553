// @ts-ignore
import { FadeIn } from 'animate-css-styled-components'
import styled from 'styled-components'

import { IconCloseModal } from '@avantstay/backoffice-vectors'

import { mainColors, shadowColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { rgba } from '../../utils/cssUtils'

export const Root = styled(FadeIn)<{ zIndex?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex }) => zIndex || 99};
`

export const Background = styled.div`
  background: ${rgba(mainColors.primaryMedium, 0.4)};
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

export const CardStyled = styled.div<{ isFullScreen?: boolean; overflowHidden?: boolean }>`
  background: ${mainColors.white};
  box-shadow: 0px 4px 30px ${rgba(shadowColors.blur, 0.15)};
  border-radius: 2px;
  pointer-events: auto;
  margin-bottom: 0;
  overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'auto')};
  width: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'calc(100% - 32px)')};
  max-width: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'calc(100% - 32px)')};
  height: ${({ isFullScreen }) => (isFullScreen ? '100%' : 'calc(100% - 60px)')};

  ${medias.MD_SCREEN`
    width: auto;
    height: auto;
    max-width: 900px;
    max-height: calc(100% - 60px);
  `}
`

export const CloseModal = styled.div`
  position: relative;
`

export const CloseModalIcon = styled(IconCloseModal)`
  position: absolute;
  top: 20px;
  left: 16px;
  color: ${mainColors.neutralDark};
  height: 30px;
  width: 30px;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }

  ${medias.MD_SCREEN`
    left: unset;
    right: 20px;
  `}
`
