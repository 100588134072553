import { createGlobalStyle, css } from 'styled-components'
import * as WebFont from 'webfontloader'

import { rgba } from '@avantstay/avantstay-ui/lib/utils/cssUtils'
import {
  _S_Medium,
  _XS_Normal,
  classNames,
  mainColors,
  PaginateContainer as PaginationStyles,
  rsuite,
  semanticColors,
  shadowColors,
  zIndex,
} from '@avantstay/backoffice-core'

import { IS_PRODUCTION } from '@utils/constants'

import backgroundImage from './dev-env-bg.png'

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700'],
  },
})

const sansSerif = css`
  font-family: 'Roboto', sans-serif;
`

const bouncehAnimation = css`
  @keyframes bounceh {
    0% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(+10px);
    }
    100% {
      transform: translateX(-10px);
    }
  }
`

export const globalStyles = css`
  ${rsuite}

  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent !important;
    margin: 0;
  }

  b {
    font-weight: bold;
  }

  body,
  button,
  input,
  textarea {
    font-size: 16px;
    ${sansSerif}
  }

  strong {
    font-weight: bold;
  }

  body {
    color: unset;
    line-height: unset;
    font-weight: normal;
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  button {
    border: none;
  }

  .toggleDateRangePicker {
    margin-top: 14px;
  }

  .rootDateRangePicker {
    z-index: ${zIndex.FIXED_ACTIONS_MOBILE_CONTAINER} !important;
  }

  .dateRangePicker {
    .rdr-DateRange {
      margin-top: 4px;
    }
  }

  .rdr-DateRange-container {
    min-height: auto !important;
  }

  .dueDatePicker {
    .rdr-DateRange {
      padding-bottom: 50px !important;
    }

    .rdr-DayWrapper {
      &.rdr-SpecialDay {
        border-radius: 0;
        overflow: hidden;

        &:before {
          content: '';
          position: absolute;
          top: 2px;
          left: 0;
          width: 100%;
          height: calc(100% - 4px);
          box-sizing: border-box;
        }

        &:after {
          content: '';
          position: absolute;
          top: 2px;
          right: 2px;
          width: calc(100% - 2px);
          height: calc(100% - 4px);
          box-sizing: border-box;
        }

        &.block:before {
          background-color: ${semanticColors.warningExtraLight};
        }

        &.occupied:before {
          background-color: ${mainColors.accentExtraLight};
        }

        &.checkIn:before,
        &.blockStart:before {
          border-radius: 2px 0 0 6px;
          left: calc(100% / 2 + 2px);
          width: 100%;
          background-color: ${mainColors.accentExtraLight};
          transform: skew(-40deg, 0deg);
        }

        &.checkOut:after,
        &.blockEnd:after {
          border-radius: 0 6px 2px 0;
          right: calc(100% / 2 + 2px);
          transform: skew(-40deg, 0deg);
        }

        &.checkOut:after {
          background-color: ${mainColors.accentExtraLight};
        }

        &.blockStart:before,
        &.blockEnd:after,
        &.block:before {
          background-color: ${semanticColors.warningLight};
        }
      }
    }
  }

  ${bouncehAnimation}

  ${PaginationStyles}

  .multiSelector {
    margin-top: 4px;
    z-index: 100;
    min-width: 149px !important;
    max-width: 200px;
    background: ${mainColors.white};
    border: 1px solid ${mainColors.neutralLight};
    border-radius: 2px;
    box-shadow: ${rgba(shadowColors.blur, 0.4)} 0px 4px 16px;

    .rs-picker-search-bar-input {
      min-width: auto !important;
    }
  }

  // google maps autocomplete

  .pac-container {
    ${_XS_Normal};
    background-color: ${mainColors.white};
    border-radius: 4px;
    border: 1px solid ${mainColors.neutralMedium};
    box-shadow: 0 4px 16px ${rgba(shadowColors.blur, 0.4)};
    margin-top: 10px;
    min-width: max-content;
    margin-left: -30px;
  }

  .pac-icon {
    display: none;
  }

  .pac-item {
    border-top-width: 0;
    cursor: pointer;
    display: block;
    ${_XS_Normal};
    padding: 6px 12px;

    &:hover {
      background-color: ${mainColors.neutralLight};
    }

    &:first-child {
      margin-top: 6px;
    }

    &:last-child {
      margin-bottom: 6px;
    }
  }

  .pac-item-query {
    ${_S_Medium};
  }

  .pac-logo:after {
    display: none;
  }

  .pac-matched {
    ${_S_Medium};
  }

  // Data-Grid

  .${classNames.gridHeaderWithFloatingFilter} {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  #new-version-notification-modal {
    position: fixed;
    align-items: center;
    justify-content: center;
    display: flex;
    bottom: 20px;
    left: 20px;
    right: 20px;
    z-index: ${zIndex.INFINITE};
  }
`

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${mainColors.neutralLight};


    &::after {
      ${
        IS_PRODUCTION
          ? ''
          : `
          display: block;
          pointer-events: none;
          content: '';
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 9999;
          background: url(${backgroundImage});
          background-repeat: repeat;
        `
      }
    }
  }

  ${globalStyles}
`

export default GlobalStyle
