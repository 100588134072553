import React, { Ref } from 'react'

function _IconComparison(props: React.SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <g clipPath="url(#clip0_2013_32025)">
        <path
          d="M7.99912 10.3882C7.99912 10.7564 7.70065 11.0548 7.33245 11.0548H1.36296C0.994762 11.0548 0.696289 10.7564 0.696289 10.3882V1.3477C0.696289 0.979504 0.994762 0.68103 1.36296 0.68103H7.33245C7.70065 0.68103 7.99912 0.979504 7.99912 1.3477V10.3882Z"
          stroke="white"
          strokeLinejoin="round"
        />
        <path
          d="M7.99902 5.61173C7.99902 5.24355 8.29749 4.94507 8.66569 4.94507L14.6352 4.94508C15.0034 4.94508 15.3019 5.24355 15.3019 5.61174V14.6522C15.3019 15.0204 15.0034 15.3189 14.6352 15.3189H8.66569C8.29749 15.3189 7.99902 15.0204 7.99902 14.6522V5.61173Z"
          stroke="white"
          strokeLinejoin="round"
        />
        <path
          d="M3.81152 7.38613L5.32992 5.86788L3.81152 4.34949"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1869 8.61365L10.6685 10.1319L12.1869 11.6503"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.33001 5.8678L0.695312 5.86797"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6685 10.1321L15.3032 10.132"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2013_32025">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconComparison = React.forwardRef(_IconComparison)
