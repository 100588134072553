import {
  CommonChannelSettingsView,
  HomeAwaySettingsView,
  IDate,
  LeadSource,
  LocalDate,
  OnlineTravelAgency,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

export interface HomesDistributionsProps {
  homeId?: string
  ota: OnlineTravelAgency
}

export interface HomeCustomerExperienceIndicators {
  homeId: string
  from: IDate
  to: IDate
}

export interface AirbnbSettingsView {
  headline: string
  propertyType: string
  listingType?: string
  checkInOptionCategory?: string
  checkInOptionInstruction?: string
  synchronizationCategory?: string
  cancellationPolicy?: string
  cancellationNonRefundablePricePercent?: number
  earlyBirdDiscount: boolean
  earlyBirdDiscountDays?: number
  earlyBirdDiscountPercent?: number
  lastMinuteBirdDiscount: boolean
  lastMinuteBirdDiscountDays?: number
  lastMinuteBirdDiscountPercent?: number
  propertyTypeGroup?: string
  securityDeposit: number
  permitId: string
  leadTime: number
  useUnitLeadTime: boolean
  directions: string
  roomNumber: string
  manual: string
}

export interface DistributionListings {
  propertyType?: string
  propertyTypeGroup?: string
}

export interface DistributionSettingsView {
  commonChannelSettings: CommonChannelSettingsView
  homeAwaySettings?: HomeAwaySettingsView
  airbnbSettings?: AirbnbSettingsView
}

export interface HomeListingView {
  homeId?: UUID
  source?: LeadSource
  title?: string
  externalHomeId?: string
  description?: string
  createdAt?: IDate
  updatedAt?: IDate
  distributionSettings?: DistributionSettingsView
  space?: string
  access?: string
  interaction?: string
  neighborhood?: string
  transit?: string
  houseRules?: string
  notes?: string
  summary?: string
  channels?: {
    active: boolean
    ota: OnlineTravelAgency
  }[]
}

export interface ListingDescriptionFormFields {
  title?: string
  description?: string
  space?: string
  access?: string
  interaction?: string
  neighborhood?: string
  transit?: string
  notes?: string
  houseRules?: string
  headline?: string
  summary?: string
}

export interface UpdateHomeListingProps {
  agency: OnlineTravelAgency
  formFields: ListingDescriptionFormFields
}

export interface UpdateHomeListingMutation extends UpdateHomeListingProps {
  homeId: string
}

export interface SyncOtaDistributionProps {
  activeSection?: string
  syncAmenities?: boolean
  syncAvailability?: boolean
  syncFeesAndTaxes?: boolean
  syncListingContent?: boolean
  syncPeriod: {
    start: LocalDate
    end: LocalDate
  }
  syncPhotos?: boolean
  syncPricing?: boolean
  syncRooms?: boolean
}

export interface SyncOtaDistributionMutation extends SyncOtaDistributionProps {
  homeId: string
}

export enum DistributionChannelsSubSection {
  guestControls = 'guest_controls',
  listingDescription = 'listing_description',
  listingInfo = 'listing_info',
  propertyDetails = 'property_details',
  tripIssues = 'trip_issues',
}

export enum DistributionNavEnum {
  airbnb = 'airbnb',
  rentalsUnited = 'rentals_united',
  vrbo = 'vrbo',
  listingsAndDistribution = 'listings-and-distribution',
  cancellationPolicies = 'cancellation-policies',
  highlights = 'highlights',
}

export enum OtaSyncOptions {
  amenities = 'AMENITIES',
  availability = 'AVAILABILITY',
  feesAndTaxes = 'FEES_AND_TAXES',
  listingContent = 'LISTING_CONTENT',
  photos = 'PHOTOS',
  pricing = 'PRICING',
  rooms = 'ROOMS',
}
