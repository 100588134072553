import arriereBackoffice, {
  BackOfficePrivilegeName,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { trackError } from '@tracking/customEventReport'

export const getUserPermissions = () => {
  return arriereBackoffice.queries.me
    .memoRaw({
      __alias: 'getUserPermissions',
      id: true,
      email: true,
      roles: { id: true, name: true },
      groupedPrivileges: {
        privileges: {
          actionKinds: {
            action: true,
            id: true,
          },
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        trackError('Attempt to get user permissions', { errors })
        throw new Error(errors[0]?.message)
      }

      localStorage.setItem('user_email', data.email)

      const userPermissions = data.groupedPrivileges.reduce(
        (accPermissions: BackOfficePrivilegeName[], currentPermission) => {
          const { privileges } = currentPermission
          const groupActionKinds = privileges.map(permission => permission.actionKinds).flat()
          const allPermissionsForGroup = groupActionKinds.map(permission => permission.id)

          return [...accPermissions, ...allPermissionsForGroup]
        },
        [],
      )

      return {
        voyageId: data.id,
        userPermissions,
        roles: data.roles,
      }
    })
}
