import styled, { css } from 'styled-components'

import { IconCloseCircle, IconSearch } from '@avantstay/backoffice-vectors'

import { mainColors, shadowColors } from '../../styles/colors'
import { medias } from '../../styles/medias'
import { _M_Bold, _S_Normal, _XS_Bold } from '../../styles/typography'
import { rgba } from '../../utils/cssUtils'
import { ICheckPickerSizeProps } from './CheckPicker.types'

export const MenuStyle = {
  zIndex: 9999,
  background: mainColors.white,
  border: `1px solid ${mainColors.neutralLight}`,
  borderRadius: 2,
  boxShadow: `0px 4px 16px ${rgba(shadowColors.blur, 0.4)}`,
  minWidth: 240,
}

export const LoadingContainer = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
`

export const Container = styled.div<
  ICheckPickerSizeProps & { active: boolean; disabled?: boolean }
>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 80px;
  height: ${({ small }) => (small ? '36px' : '50px')};
  min-height: ${({ small }) => (small ? '36px' : '54px')};
  background-color: white;
  border: 2px solid ${props => (props.active ? mainColors.accentMedium : mainColors.neutralMedium)};
  border-radius: 2px;

  ${medias.LG_SCREEN`
    min-width: 180px;
  `}

  &:focus-within {
    border: 2px solid ${mainColors.accentMedium};
  }

  .rs-picker-toggle-active {
    box-shadow: none;
  }

  .rs-picker-toggle-placeholder {
    color: ${mainColors.black} !important;
  }

  &:hover {
    border-color: ${({ active }) => !active && mainColors.neutralDark};
  }

  ${({ disabled }) =>
    disabled
      ? css`
          background-color: ${mainColors.neutralExtraLight};
          border: 2px solid ${mainColors.neutralMedium};
          color: ${mainColors.neutralExtremelyDark};

          &:hover {
            border-color: ${mainColors.neutralMedium};
          }
        `
      : null};
`

export const MenuWrapper = styled.div<{ removeBottomBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  margin: 4px 0 0 12px;
  padding-bottom: 8px;
  max-height: 120px;
  overflow-y: auto;
  ${({ removeBottomBorder }) =>
    !removeBottomBorder && `border-bottom: 1px solid ${mainColors.neutralMedium}`};

  .rs-checkbox {
    transform: translateX(-10px);
  }
`

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 12px;
`

export const GroupMenu = styled.div`
  ${_XS_Bold};
  color: ${mainColors.neutralExtremelyDark};
  text-transform: uppercase;
`
export const CustomMenu = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-height: 320px;
  overflow-y: auto;
`

export const CustomTitle = styled.span`
  ${_S_Normal};
  margin-right: 3px;
`

export const CustomLabelWrapper = styled.div`
  span {
    padding: 5px 10px;
  }
`

export const DropdownIndicator = styled.div<{ disabled?: boolean }>`
  position: absolute;
  right: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${mainColors.neutralExtraDark};
  cursor: pointer;

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : null};
`

export const SearchBarClearIcon = styled(IconCloseCircle)`
  position: absolute;
  top: 22px;
  right: 20px;
  width: 16px;
  height: 16px;
  color: ${mainColors.neutralExtraDark};
  cursor: pointer;

  &:hover {
    color: ${mainColors.neutralExtremelyDark};
  }
`

export const SearchBarMagIcon = styled(IconSearch)`
  position: absolute;
  top: 22px;
  left: 20px;
  width: 16px;
  height: 16px;
  color: ${mainColors.neutralDark};
`

export const Subtitle = styled.span`
  ${_M_Bold};
  color: ${mainColors.neutralExtremelyDark};
`
