export enum zIndex {
  DEFAULT = 1,
  TABLE_PORTAL,
  TABLE_HEADER,
  TABLE_CONTENT,
  EDITABLE_ACTION_BAR,
  HOVERED_DIVISION,
  MOBILE_MENU_RIBBON,
  FIXED_TABLE_COLUMN,
  FIXED_TABLE_HEADER,
  TOOLTIP,
  FIXED_ACTIONS_CONTAINER,
  TABLE_AVATAR,
  FILTERS,
  HEADER,
  INTERNAL_SECTION_GRADIENT,
  FLOATING_CONTAINER_TOGGLER,
  PORTAL,
  FOLD_OUT_MENU,
  MOBILE_TABLE_CARD,
  PRICING_TAPE_CHART_OVERLAY = 99,
  FIXED_ACTIONS_MOBILE_CONTAINER = 100,
  GLOBAL_LOADING,
  PRICING_TAPE_CHART_UNSAVED_CHANGES,
  PRICING_TAPE_CHART_PROPERTIES_COLUMN,
  PRICING_TAPE_CHART_BULK_EDIT_COLUMN,
  PRICING_TAPE_CHART_DISCARD_CHANGES_MODAL,
  NEW_VERSION_NOTIFICATION_MODAL,
}
