import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconWorkflow(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} ref={ref}>
      <path
        d="M12.4688 7.3125H2.15625C1.875 7.3125 1.6875 7.125 1.6875 6.84375V4.03125C1.6875 3.75 1.875 3.5625 2.15625 3.5625H12.4688C12.75 3.5625 12.9375 3.75 12.9375 4.03125V6.84375C12.9375 7.03125 12.75 7.3125 12.4688 7.3125Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8438 20.4375H7.78125C7.5 20.4375 7.3125 20.25 7.3125 19.9688V17.1562C7.3125 16.875 7.5 16.6875 7.78125 16.6875H21.8438C22.125 16.6875 22.3125 16.875 22.3125 17.1562V19.9688C22.3125 20.1562 22.125 20.4375 21.8438 20.4375Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9375 5.4375H15.75C16.0312 5.4375 16.2188 5.625 16.2188 5.90625V13.875"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3438 12L16.2188 13.875L18.0938 12"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const IconWorkflow = forwardRef(_IconWorkflow)
