import arriereBackoffice from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Alert, pluralize } from '@avantstay/backoffice-core'

import {
  RangeDates,
  StaffMutationProps,
} from '@new/domains/operations/screens/Markets/Markets.types'

const Property = {
  id: true,
  name: true,
  region: {
    id: true,
    name: true,
  },
}

const Zone = {
  id: true,
  name: true,
  regionId: true,
}

const Staff = {
  backOfficeUserId: true,
  email: true,
  name: true,
}

const Availabilities = {
  atDate: true,
  kind: true,
}

export default {
  async getZones(regionId: string) {
    return arriereBackoffice.queries
      .zones({
        __alias: 'getZones',
        searchZones: {
          __args: {
            regionId,
          },
          properties: Property,
          staffCount: true,
          propertiesCount: true,
          staff: Staff,
          zone: Zone,
        },
      })
      .then(results => results.searchZones)
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async getPropertiesByMarket(regionId: string) {
    return arriereBackoffice.queries
      .zones({
        __alias: 'getProperties',
        searchProperties: {
          __args: {
            regionId,
          },
          zone: Zone,
          property: Property,
        },
      })
      .then(results => results.searchProperties)
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async addZone(name: string, regionId: string, homeIds: string[]) {
    return arriereBackoffice.mutations
      .zones({
        __alias: 'addZone',
        addZone: {
          __args: {
            name,
            regionId,
            homeIds,
          },
        },
      })
      .then(results => {
        Alert.Success(`${name} successfully created.`)
        return results.addZone
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async editZone(zoneId: string, homeIds: string[], zoneName: string) {
    return arriereBackoffice.mutations
      .zones({
        __alias: 'editZone',
        updateZoneHomes: {
          __args: {
            zoneId,
            homeIds,
          },
        },
      })
      .then(results => {
        Alert.Success(`${zoneName} successfully updated.`)
        return results.updateZoneHomes
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async deleteZone(zoneId: string, zoneName: string) {
    return arriereBackoffice.mutations
      .zones({
        __alias: 'deleteZone',
        deleteZone: {
          __args: {
            zoneId,
          },
        },
      })
      .then(results => {
        Alert.Success(`${zoneName} successfully deleted.`)
        return results.deleteZone
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async getStaffs(regionId: string, period: RangeDates) {
    return arriereBackoffice.queries
      .staff({
        __alias: 'getStaffs',
        searchStaff: {
          __args: {
            regionId,
            period,
          },
          backOfficeUserId: true,
          name: true,
          phone: true,
          backupPhone: true,
          email: true,
          role: true,
          shortRole: true,
          zones: Zone,
          properties: Property,
          availabilities: Availabilities,
          defaultWorkingDays: true,
          assignedHomes: Property,
          lighthousePhone: true,
          onCallPhone: true,
          spokePhone: true,
        },
      })
      .then(results => {
        return results.searchStaff
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async addOrUpdateStaff(staffs: StaffMutationProps[], range: RangeDates, marketId: string) {
    return arriereBackoffice.mutations
      .staff({
        __alias: 'addOrUpdateStaff',
        addOrUpdateStaff: {
          __args: {
            staff: staffs,
            updateRange: range,
            regionId: marketId,
          },
        },
      })
      .then(results => {
        Alert.Success(`${pluralize(staffs.length, 'Staffs', 'Staff')} successfully created.`)
        return results.addOrUpdateStaff
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },

  async deleteStaff(backOfficeUserId: string) {
    return arriereBackoffice.mutations
      .staff({
        __alias: 'deleteStaff',
        deleteUserRole: {
          __args: {
            backOfficeUserId,
          },
        },
      })
      .then(results => {
        Alert.Success('Staff successfully deleted.')
        return results.deleteUserRole
      })
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },

  async fetchOperationUserRoles() {
    return arriereBackoffice.queries.operationUserRoles
      .memoRaw({
        __alias: 'getOperationUserRoles',
        role: true,
        shortRole: true,
      })
      .then(({ data, errors }) => {
        if (errors?.length) {
          Alert.Error('Error: Operation User roles', errors[errors?.length - 1].message)
        }

        return data
      })
  },
}
