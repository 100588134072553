import styled from 'styled-components'

import { _M_Medium, _XS_Bold } from '../../styles/typography'

export const RatingBar = styled.div`
  display: flex;
  align-items: center;
`

export const RatingValue = styled.span<{ $isMediumSize?: boolean }>`
  ${({ $isMediumSize }) => ($isMediumSize ? _M_Medium : _XS_Bold)};
  color: ${props => props.color};
`
