import arriereBackoffice, {
  GuestDamagesReportSorting,
  GuestIncidentalsStatus,
  GuestIncidentalType,
  PaginationInput,
  SortOrder,
  UUID,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

interface GuestDamageReportParams {
  sortBy?: GuestDamagesReportSorting
  sortOrder: SortOrder
  pagination: PaginationInput
  guestIncidentalStatuses?: GuestIncidentalsStatus[]
  guestIncidentalTypes?: GuestIncidentalType[]
  regionIds?: UUID[]
  dateOpened?: { start: string; end: string }
  caseNumber?: number
}

export const getReport = async (params: GuestDamageReportParams) => {
  const response = await arriereBackoffice.queries.cases({
    __alias: 'getGuestDamagesReport',
    getGuestDamagesReport: {
      __args: params,
      pages: true,
      total: true,
      results: {
        dateTime: true,
        assignedGxAgent: true,
        guestIncidentalsStatus: true,
        guestIncidentalType: true,
        amount: true,
        market: true,
        caseNumber: true,
        totalAmountRequested: true,
        totalAmountReceived: true,
        bookingHash: true,
        confirmationIdWithGuestName: true,
        workOrderId: true,
        bookingReservationTypes: {
          bookingId: true,
          id: true,
          name: true,
          externalName: true,
          isOwnerRelated: true,
          isRevenueGenerating: true,
        },
        bookingSource: true,
      },
    },
  })

  return response.getGuestDamagesReport
}
