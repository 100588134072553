import { differenceInCalendarDays } from 'date-fns'

import { parseISODate } from './format'
import { UUID_PATTERN } from './patterns'

export const checkStringPattern = (base: string, textToCompare: string) => {
  const keywordString = base.trim().replace(/[^a-zA-Z0-9\s]+/g, '')
  const pattern = new RegExp(`\\b${keywordString}`, 'gi')

  return Boolean(textToCompare.match(pattern))
}

export function ensureRight(anything: string, suffix: string): string {
  if (anything === '-') return anything

  const anythingStr = `${anything}`
  return anythingStr.endsWith(suffix) ? anythingStr : `${anythingStr}${suffix}`
}

export function ensureLeft(anything: string, prefix: string): string {
  if (anything === '-') return anything

  const anythingStr = `${anything}`
  return anythingStr.startsWith(prefix) ? anythingStr : `${prefix}${anythingStr}`
}

export const removeSpaces = (s?: string): string | undefined => (s ? s.replace(/\s/g, '') : s)

export function pluralize(
  number: number | undefined,
  plural: string,
  singular: string,
): string | undefined {
  if (number === undefined) {
    return undefined
  }

  return number !== 1 ? plural : singular
}

export const pluralizeS = (number: number | undefined) => pluralize(number, 's', '')

export const addZeroToTimeString = (str?: string): string | undefined => {
  if (!str) return str

  const strSplited = str.split(':')

  if (strSplited[0].length <= 1) {
    return 0 + str
  }

  return str
}

export const totalNights = (start: string | Date, end: string | Date): string | undefined => {
  if (Number.isInteger(differenceInCalendarDays(parseISODate(end), parseISODate(start)))) {
    return `${differenceInCalendarDays(parseISODate(end), parseISODate(start))} ${pluralize(
      differenceInCalendarDays(parseISODate(end), parseISODate(start)),
      'nights',
      'night',
    )}`
  }
  return undefined
}

export const checkIfValidUUID = (value: string) => {
  return UUID_PATTERN.test(value)
}

export const pluralizeText = (value = 0, plural: string, singular: string) =>
  value > 1 || value === 0 ? plural : singular

export const contains = (searchBase: string, searchString: string) =>
  new RegExp(searchString, 'i').test(searchBase)

export const capitalizeAcronymsInName = (name: string): string => {
  const acronyms = ['vrbo', 'Vrbo']

  return name
    .split(' ')
    .map(word =>
      acronyms.includes(word.toLowerCase())
        ? word.toUpperCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(' ')
}

export const validateEmail = (email: string) => {
  const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
  return emailRegex.test(email)
}

export const humanizeSnakeCase = (snakeCaseString?: string) => {
  return snakeCaseString
    ?.split('_')
    ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ')
}
