import React, { forwardRef, Ref, SVGProps } from 'react'

function _IconDot(props: SVGProps<SVGSVGElement>, ref?: Ref<SVGSVGElement>) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props} ref={ref}>
      <rect width="10" height="10" rx="5" fill="currentColor" />
    </svg>
  )
}

export const IconDot = forwardRef(_IconDot)
