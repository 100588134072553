import styled, { css } from 'styled-components'

import { IconResizer } from '@avantstay/backoffice-vectors'

import { mainColors, semanticColors } from '../../styles/colors'
import { _M_Normal, _XS_Normal } from '../../styles/typography'
import { InputReset } from '../../styles/utils'
import { TextAreaStyledProps } from './TextArea.types'

export const IconResize = styled(IconResizer)`
  color: ${mainColors.neutralDark};
  position: absolute;
  bottom: 5px;
  right: 1px;
  max-width: 10px;
  max-height: 10px;
  z-index: -1;
`

export const TextAreaDefault = styled.textarea<TextAreaStyledProps>`
  ${InputReset};
  ${_M_Normal};
  background-color: transparent;
  border: 2px solid ${mainColors.neutralMedium};
  border-radius: 2px;
  color: ${mainColors.black};
  width: 100%;
  height: ${({ rows }) => (rows ? 'auto' : '76px')};
  padding: 14px;
  outline: none;
  resize: vertical;
  width: 100%;
  min-height: 74px;

  &:hover {
    border-color: ${mainColors.neutralDark};
  }

  &::placeholder {
    ${_M_Normal};
    color: ${mainColors.neutralExtraDark};
  }

  &:focus {
    border-color: ${mainColors.accentMedium};
  }

  &:disabled {
    -webkit-text-fill-color: ${mainColors.neutralExtraDark};

    border-color: ${mainColors.neutralLight};
    color: ${mainColors.neutralExtraDark};
    cursor: not-allowed;
    pointer-events: none;
    opacity: 1;

    &::placeholder {
      color: ${mainColors.neutralExtraDark} !important;
    }
  }

  ${({ warning }) =>
    warning &&
    `
    border-color: ${semanticColors.warningMedium};

    &:focus {
      border-color: ${semanticColors.warningMedium};
    }

    &:hover {
      border-color: ${semanticColors.warningMedium};
    }
  `}

  ${({ error }) =>
    error &&
    `
    border-color: ${semanticColors.failureMedium};

    &:focus {
      border-color: ${semanticColors.failureMedium};
    }

    &:hover {
      border-color: ${semanticColors.failureMedium};
    }
  `}

  ${({ withTag, tagWidth }) =>
    withTag &&
    `
      padding-right: ${tagWidth || 90}px;
  `}

  ${({ readOnly }) =>
    readOnly &&
    `
      color: ${mainColors.black} !important;
      background-color: ${mainColors.neutralLight};
      border-color: transparent;
      cursor: not-allowed;
      &:hover {
        border-color: transparent;
      }
  `}
`

export const TagContainer = styled.div`
  position: absolute;
  top: 0;
  right: 11px;
  display: flex;
  align-items: center;
  height: 36px;
  margin-left: 10px;
  margin-top: 4px;
`

export const Container = styled.div<{ centralizeBadge?: boolean }>`
  position: relative;

  ${({ centralizeBadge }) =>
    centralizeBadge
      ? css`
          display: flex;
          align-items: center;

          ${TagContainer} {
            height: 100%;
            top: auto;
            margin-top: 0px;
          }
        `
      : null}
`

export const CharCount = styled.div<{ isOverLimit: boolean }>`
  ${_XS_Normal};

  color: ${p => (p.isOverLimit ? semanticColors.failureMedium : mainColors.neutralExtraDark)};
  inset: auto 0 calc(100% + 4px) auto;
  position: absolute;
`
