import React, { ErrorInfo } from 'react'
import { PropsWithChildren } from 'react'

import { safeLazy } from '@avantstay/backoffice-core'

import { datadogRum } from '@datadog/browser-rum'

const ErrorScreen = safeLazy(() => import('@screens/Error.screen'))

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.log('error', error)
    console.log('info', info)
    const renderingError = new Error(error.message)
    renderingError.name = `ErrorBoundary`
    renderingError.stack = info.componentStack ?? undefined
    renderingError.cause = error

    datadogRum.addError(renderingError)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />
    }

    return this.props.children
  }
}
