import { get } from 'lodash'
import * as React from 'react'
import { Redirect, Route, Router, Switch, withRouter } from 'react-router-dom'
import { StyleSheetManager } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'

import { AlertContext, Loading, safeLazy } from '@avantstay/backoffice-core'

import Authorized from '@new/__global/components/Authorized'

import AuthProvider from '@contexts/Auth/AuthProvider'
import StoreValuesProvider from '@contexts/StoreValuesContext/StoreValuesProvider'
import isPropValid from '@emotion/is-prop-valid'
import { ROUTES } from '@routes'
import GlobalStyle from '@styles/globalStyles'
import history from '@utils/history'
import { FeatureToggleProvider } from '@utils/optimizely'
import { withErrorBoundary } from '@utils/withErrorBoundary'

import { MainScreensSwitch } from './MainScreensSwitch'

const Login = withErrorBoundary(safeLazy(() => import('@screens/Login.screen')))
const ErrorAuthProcess = withErrorBoundary(safeLazy(() => import('@screens/ErrorAuth.screen')))

const renderAuthorizing = () => <Route path={ROUTES.login.path} exact component={Login} />

const renderUnauthorized = withRouter(({ location }) => {
  const shouldRedirect = location.pathname !== ROUTES.login.path
  return (
    <>
      <Route path={ROUTES.login.path} exact component={Login} />
      {shouldRedirect && (
        <Redirect
          to={{
            pathname: ROUTES.login.path,
            state: { nextLocation: location },
          }}
        />
      )}
    </>
  )
})

function MainRouter() {
  return (
    <StyleSheetManager
      shouldForwardProp={(propName, elementToBeRendered) => {
        return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
      }}
    >
      <Router history={history}>
        <QueryParamProvider history={history}>
          <AuthProvider>
            <FeatureToggleProvider>
              <StoreValuesProvider>
                <React.Suspense fallback={<Loading absolute />}>
                  <Authorized
                    renderUnauthorized={renderUnauthorized}
                    renderAuthorizing={renderAuthorizing}
                    errorComponent={ErrorAuthProcess}
                  >
                    {({ isSignedIn, location }) => {
                      const shouldRedirectToDashboard =
                        isSignedIn &&
                        location.pathname === ROUTES.login.path &&
                        get(location.state, 'nextLocation.pathname') === ROUTES.home.path
                      return shouldRedirectToDashboard ? (
                        <Switch>
                          <Redirect to={ROUTES.dashboard.path} />
                        </Switch>
                      ) : (
                        <MainScreensSwitch />
                      )
                    }}
                  </Authorized>
                </React.Suspense>
                <GlobalStyle />
                <AlertContext />
              </StoreValuesProvider>
            </FeatureToggleProvider>
          </AuthProvider>
        </QueryParamProvider>
      </Router>
    </StyleSheetManager>
  )
}

export default MainRouter
