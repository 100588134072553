import { CSSProperties, ReactNode } from 'react'
import { DraggableProvided } from 'react-beautiful-dnd'

import {
  AssetContextType,
  CustomFieldRestriction,
  IDate,
  UUID,
  ZonedDateTime,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

import { DragAndDropStatusProps } from '../DragAndDropGrid/_types'
import { UseManageAssetsStateProps } from './hooks/useManageAssets'

interface ActionAuthor {
  kind: string
  name: string
}

export interface UploadAreaAsset {
  id: UUID
  context: AssetContextType
  s3Bucket: string
  s3Key: string
  public: boolean
  targetId?: UUID
  createdAt: ZonedDateTime
  updatedAt?: ZonedDateTime
  extension?: string
  size?: number
  fileName?: string
  url: string
  createdByAuthor?: ActionAuthor
}

export interface AssetWithDocumentProps extends DragAndDropStatusProps {
  id: UUID
  context?: AssetContextType
  s3Bucket?: string
  s3Key?: string
  public?: boolean
  targetId?: UUID
  createdAt?: IDate
  updatedAt?: IDate
  extension?: string
  size?: number
  fileName?: string
  url: string
  documentId?: UUID
  description?: string
  customInfo?: ReactNode
  review?: {
    decision?: string
    reviewedAt?: IDate
  }
}

export interface FileWithError {
  [id: string]: File | undefined
}

export enum DragAreaStatusView {
  idle,
  dragOver,
}

export interface DropAreaRootProps {
  active: boolean
  disabled?: boolean
}

export enum UploadAreaType {
  document = 'DOCUMENT',
  general = 'GENERAL',
}

export interface UploadAreaProps {
  targetId: string
  uploadService: (
    targetId: string,
    file: File,
    fileName: string,
    progressFileFn: (id: string) => (progress: number) => void,
  ) => Promise<any>
  uploadSingleFile?: boolean
  filePreviewType?: FilePreviewType
  isSelecting?: boolean
  hidePreview?: boolean
  title?: string
  fieldRestriction?: CustomFieldRestriction
  initialFiles?: AssetWithDocumentProps[]
  areaStyles?: CSSProperties
  uploadAreaType?: UploadAreaType
  dropAreaMessage?: ReactNode
  viewMode?: FileViewMode
  selectedFiles?: UUID[]
  enabledAssets?: string[]
  filePreviewActions?: ({ index, assetId }: { index: number; assetId?: string }) => JSX.Element
  onChange?: (files: UploadAreaAsset[] | AssetWithDocumentProps[]) => void
  onChangeWithStatus?: ({
    files,
    filesUploadProgress,
    filesWithError,
  }: UseManageAssetsStateProps) => void
  onRemoveFile?: (file: AssetWithDocumentProps) => void
  onSelectFile?: (id: UUID) => void
  onToggleEnableAsset?: (file: AssetWithDocumentProps) => void
  accept?: string
  disabled?: boolean
  disableUpload?: boolean
  forceResetFiles?: unknown
  previewTitleActions?: ReactNode
  photoGalleryFilters?: ReactNode
  filteredImages?: string[]
  disableDragAndDrop?: boolean
}

export enum FilePreviewType {
  documents = 'DOCUMENTS',
  customField = 'CUSTOM_FIELD',
  photoGallery = 'PHOTO_GALLERY',
  referencePhoto = 'REFERENCE_PHOTO',
}

export enum FileViewMode {
  grid = 'GRID_VIEW',
  list = 'LIST_VIEW',
}

export interface PreviewProps {
  file: AssetWithDocumentProps
  isImageCover: boolean
  hasLowResolution: boolean
  filesWithError?: FileWithError
  isFileEnabled?: boolean
  filePreviewType?: FilePreviewType
  uploadAreaType?: UploadAreaType
  onOpenImagePreview: (state: boolean) => void
  onMakeAsCover?: VoidFunction
}

type SingleFileSpecificType = 'onOpenImagePreview' | 'hasLowResolution' | 'isImageCover'

export interface FilePreviewActionProps {
  filePreviewActions?: ({ index, assetId, warning, error }: FilePreviewActionsProps) => ReactNode
  fileViewMode?: FileViewMode
}

export interface FilePreviewProps
  extends FilePreviewActionProps,
    Omit<PreviewProps, SingleFileSpecificType> {
  index: number
  allFiles: AssetWithDocumentProps[]
  fileUploadProgress?: number
  isFileSelected?: boolean
  isFileEnabled?: boolean
  isSelecting?: boolean
  fileCustomInfo?: ReactNode
  onRetryUpload?: (file: File) => void
  onSelectFile?: (id: UUID) => void
  providedDraggable?: DraggableProvided
  uploadAreaType?: UploadAreaType
  removeFile?: (index: number) => void
  onToggleEnableAsset?: (file: AssetWithDocumentProps) => void
}

export interface FilePreviewActionsProps extends FileUploadStatus {
  index: number
  assetId: string
}

export interface ExtensionProps {
  background?: string
  color?: string
}

export interface FileUploadStatus {
  warning?: boolean
  error?: boolean
}

export interface ImageContainerProps extends FileUploadStatus {
  $isCover?: boolean
  $hasFlatBorder?: boolean
  $isDisabled?: boolean
  $viewMode?: FileViewMode
}

export interface FileWrapperProps {
  $viewMode?: FileViewMode
  $filePreviewType?: FilePreviewType
}

export interface FileResolutionProps {
  width: number
  height: number
}
