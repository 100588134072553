import { Context, createContext } from 'react'

import { UserPermissions } from '@arriere/types/Auth.types'

export interface UserProfile {
  name: string
  id: string
  email: string
  photo: string
  voyageId?: string
  permissions?: UserPermissions
  roles?: { id: string; name: string }[]
}

export type initialContextType = {
  user: UserProfile | null
  isAuthorizing: boolean
  isSignedIn: boolean
  signIn: () => void
  signOut: () => void
}

export const initialContext: initialContextType = {
  user: {} as UserProfile,
  isAuthorizing: false,
  isSignedIn: false,
  signIn: () => {},
  signOut: () => {},
}

const AuthContext: Context<initialContextType> = createContext(initialContext)

export default AuthContext
