import React, { useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'

import isDescendant from '@avantstay/avantstay-ui/lib/utils/isDescendant'

import {
  useCreatePortalRoot,
  useDisableBodyScroll,
  useOnClickOutside,
  useOnKeyDown,
} from '../../hooks'
import * as S from './Modal.styles'
import { ModalProps } from './Modal.types'

const ROOT_ID = 'portalUseModal'

function _Modal({
  className,
  ref,
  children,
  disableBodyScroll = true,
  overflowHidden = false,
  isFullScreen = false,
  isClosable = true,
  onCloseModal,
  disableCloseOnClickOutside = false,
  zIndex,
}: ModalProps): JSX.Element | null {
  const portalRoot = useCreatePortalRoot({ rootId: ROOT_ID })
  const rootRef = useRef<HTMLDivElement | null>(null)

  const handleClickOutside = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (disableCloseOnClickOutside || isDescendant(rootRef.current, e.target)) {
        return null
      }

      return isClosable && onCloseModal && onCloseModal()
    },
    [isClosable, onCloseModal, disableCloseOnClickOutside],
  )

  // Disable body scroll
  useDisableBodyScroll(true, disableBodyScroll)

  // On click oustide
  useOnClickOutside(
    rootRef,
    isClosable && !disableCloseOnClickOutside ? handleClickOutside : () => {},
  )

  // On esc keypress
  useOnKeyDown('Escape', keyWasPressed =>
    keyWasPressed && isClosable ? onCloseModal?.() : () => {},
  )

  // Return null if portal root is not found
  if (!portalRoot) {
    return null
  }

  return ReactDOM.createPortal(
    <S.Root
      className={className}
      duration="0.2s"
      onClick={handleClickOutside}
      ref={ref || rootRef}
      zIndex={zIndex}
    >
      <S.Background className="ModalBackground">
        <S.CardStyled
          className="CardStyled"
          isFullScreen={isFullScreen}
          overflowHidden={overflowHidden}
        >
          {isClosable && (
            <S.CloseModal>
              <S.CloseModalIcon onClick={onCloseModal} />
            </S.CloseModal>
          )}
          {children}
        </S.CardStyled>
      </S.Background>
    </S.Root>,
    portalRoot,
  )
}

const Modal = React.memo(_Modal)

export { Modal }
