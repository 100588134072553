import { setArriereHeaders } from '@arriere/index'
import { googleAuth } from '@contexts/Auth/methods/googleAuth'
import { trackError, trackEvent } from '@tracking/customEventReport'

export const setToken = async () => {
  const auth = await googleAuth

  let { id_token, expires_at } = auth.currentUser.get().getAuthResponse()
  const now = new Date().getTime()

  if (now >= expires_at) {
    trackEvent('Auth: Refreshing user token', {})
    const authResponse = await auth.currentUser.get().reloadAuthResponse()

    id_token = authResponse.id_token

    if (authResponse.id_token == null) {
      trackError('Auth: Error refreshing user token', { authResponse })
    }
  }

  localStorage.setItem('id_token', id_token)
  setArriereHeaders()
  return id_token
}

export const cleanToken = () => {
  localStorage.removeItem('id_token')
  localStorage.removeItem('user_email')
}

export const startTokenRenewalListener = () => {
  const MINUTE_IN_MILISECONDS = 30 * 1000

  setInterval(() => {
    setToken()
  }, MINUTE_IN_MILISECONDS)
}
