import { BounceInUp } from 'animate-css-styled-components'
import Lottie from 'lottie-react'
import React from 'react'
import ReactDOM from 'react-dom'

import { Button, Desktop, Mobile } from '@avantstay/backoffice-core'
import { useCreatePortalRoot } from '@avantstay/backoffice-core'
import { IconClose } from '@avantstay/backoffice-vectors'

import appLogo from './LogoAnimated.json'
import * as S from './NewVersionNotificationModal.styles'

interface NewVersionNotificationModalProps {
  onDismiss: VoidFunction
}

export const NewVersionNotificationModal = ({ onDismiss }: NewVersionNotificationModalProps) => {
  const portalRoot = useCreatePortalRoot({ rootId: 'new-version-notification-modal' })

  if (!portalRoot) return null

  return ReactDOM.createPortal(
    <BounceInUp>
      <S.Root>
        <S.IconPlusText>
          <S.Icon>
            <Lottie animationData={appLogo} loop={true} />
          </S.Icon>
          <S.Text>
            <S.Title>Update Available</S.Title>
            <S.Info>
              A new version of Voyage is out. Please save any changes you're making before
              refreshing.
            </S.Info>
          </S.Text>
        </S.IconPlusText>
        <S.Buttons>
          <Mobile>
            <Button.Secondary onClick={onDismiss}>Check later</Button.Secondary>
          </Mobile>
          <Button.Primary
            onClick={() => {
              window.location.reload()
            }}
          >
            Reload now
          </Button.Primary>

          <Desktop>
            <Button.Flat onClick={onDismiss} color="#fff">
              <IconClose style={{ margin: '8px', flexShrink: 0, width: '20px', height: '20px' }} />
            </Button.Flat>
          </Desktop>
        </S.Buttons>
      </S.Root>
    </BounceInUp>,
    portalRoot,
  )
}
