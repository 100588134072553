import arriereBackoffice, {
  OnlineTravelAgency,
} from '@avantstay/arriere-clients/dist/arriereBackoffice'

const getHomeDistributionData = async (homeId?: string, agency?: OnlineTravelAgency) => {
  if (homeId === undefined || agency === undefined) {
    return undefined
  }

  return arriereBackoffice.queries
    .distribution({
      __alias: 'getHomeDistributionData',
      getHomeListing: {
        __args: { homeId, agency },
        homeId: true,
        title: true,
        externalHomeId: true,
        updatedAt: true,
        agencyAccount: {
          id: true,
          channelAccountId: true,
          market: true,
        },
      },
    })
    .then(result => {
      return result.getHomeListing
    })
}

export const getHomeInfoForSyncStatus = async (homeId?: string, agency?: OnlineTravelAgency) => {
  return arriereBackoffice.queries.home
    .raw({
      __alias: 'homeForSyncStatus',
      __args: { homeId },
      id: true,
      name: true,
      region: {
        id: true,
        name: true,
      },
      thumbnail: {
        url: true,
      },
      links: {
        manual: true,
        listings: {
          leadSource: true,
          url: true,
        },
      },
    })
    .then(async ({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      const homeDistribution = await getHomeDistributionData(homeId, agency)

      return {
        ...data,
        homeDistribution,
      }
    })
}

export const homeDistributionStatusFragment = (agency: OnlineTravelAgency) => ({
  category: true,
  status: {
    author: {
      name: true,
      kind: true,
    },
    startTime: true,
    updatedAt: true,
    endTime: true,
    errors: {
      code: true,
      internalCategory: true,
      reason: true,
      ts: true,
      resolution: {
        __args: { agency },
        priority: true,
        description: true,
        instructions: true,
      },
      reportedBy: true,
    },
    warnings: {
      code: true,
      internalCategory: true,
      reason: true,
      ts: true,
      resolution: {
        __args: { agency },
        priority: true,
        description: true,
        instructions: true,
      },
      reportedBy: true,
    },
    status: true,
  },
})

export const getHomeDistributionsAndListings = async (
  homeId: string,
  agency: OnlineTravelAgency,
) => {
  return arriereBackoffice.queries.home
    .raw({
      __alias: 'getHomeDistributionsAndListings',
      __args: {
        homeId,
      },
      links: {
        listings: {
          leadSource: true,
          url: true,
        },
      },
      distributions: {
        __args: {
          activeOnly: false,
          agency,
        },
        active: true,
        homeTitle: true,
        externalHomeId: true,
        homeId: true,
        agencyAccount: {
          agency: true,
          market: true,
          notes: true,
          channel: true,
        },
        status: true,
        statuses: {
          ...homeDistributionStatusFragment(agency),
        },
        lastUpdateAt: true,
      },
      listings: {
        __args: { agency },
        active: true,
        agencyAccount: {
          agency: true,
        },
        propertyType: true,
        propertyTypeGroup: true,
      },
    })
    .then(async ({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      return data
    })
}

export const getHomeSyncStatusTimeline = async (homeId: string, agency: OnlineTravelAgency) => {
  return arriereBackoffice.queries.home
    .raw({
      __alias: 'getHomeSyncStatusTimeline',
      __args: { homeId },
      distributionIssuesHistory: {
        __args: { agency },
        listingId: true,
        category: true,
        isSuccess: true,
        issues: {
          description: true,
          isError: true,
          isWarning: true,
          reportedBy: true,
          code: true,
          resolution: {
            __args: { agency },
            priority: true,
            description: true,
            instructions: true,
            errorMessage: true,
          },
        },
        resolvedBy: {
          kind: true,
          name: true,
        },
        time: true,
        issueDurationInSeconds: true,
        issueStartedAt: true,
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      return data.distributionIssuesHistory
    })
}

export const getDistributionIssueMetrics = async (ota: OnlineTravelAgency) => {
  return arriereBackoffice.queries.distribution
    .raw({
      __alias: 'getDistributionIssueMetrics',
      distributionIssueMetrics: {
        __args: { ota },
        listingsWithIssuesCount: true,
        listingsWithIssuesSince: {
          threshold: true,
          count: true,
          thresholdUnit: true,
        },
        listingsWithNewIssuesOn: {
          date: true,
          count: true,
        },
        listingsWithResolvedIssuesOn: {
          date: true,
          count: true,
        },
      },
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors.length - 1]?.message)
      }

      return data.distributionIssueMetrics
    })
}
