import styled from 'styled-components'

import { mainColors } from '../../styles'

export const HorizontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${mainColors.neutralMedium};
`
