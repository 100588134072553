import { isEmpty } from 'lodash'
import React, { forwardRef, ReactNode, Ref, useEffect } from 'react'

import { IconEye, IconEyeBlocked, Separator } from '@avantstay/backoffice-vectors'

import { useToggle } from '../../hooks/useToggle'
import { Desktop, useIsTabletOrMobile } from '../../utils/mediaQueries'
import { ClearButton, ShowColumnButton } from '../Filters/Filter.styles'
import { FilterTag } from '../Filters/FilterTag'
import { Tooltip } from '../Tooltip'
import * as S from './FiltersButton.styles'

export interface FilterAccordionProps<FilterValueType> {
  children: ReactNode
  className?: string
  filterValue: FilterValueType
  hideFilterTag?: boolean
  isAccordionOpen?: boolean
  isDateRange?: boolean
  isInlineFilter?: boolean
  isOverflowing?: boolean
  isSidebarFilter?: boolean
  onClearFilter?: (index?: number) => void
  onShowFilterAsColumn?: VoidFunction
  showFilterAsColumn?: boolean
  shouldDisableClearButton?: boolean
  shouldDisplayFilterValueAsIs?: boolean
  shouldShowClearButton?: boolean
  title: ReactNode
  hasMultipleValues?: boolean
  customFilterTag?: ReactNode
  shouldOverwriteMobileStyle?: boolean
}

export const FilterAccordion = forwardRef(
  <FilterValue,>(
    {
      children,
      className,
      filterValue,
      hideFilterTag = false,
      isAccordionOpen,
      isDateRange = false,
      isInlineFilter = false,
      isOverflowing,
      isSidebarFilter,
      onClearFilter,
      shouldDisableClearButton = false,
      shouldDisplayFilterValueAsIs,
      shouldShowClearButton = true,
      title,
      hasMultipleValues,
      customFilterTag,
      shouldOverwriteMobileStyle = false,
      showFilterAsColumn,
      onShowFilterAsColumn,
    }: FilterAccordionProps<FilterValue>,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const [isOpen, toggleIsOpen, setIsOpen] = useToggle()

    const handleSetIsOpen = () => {
      toggleIsOpen()
    }

    const renderFilterTags = (filterValues: FilterValue[]) => {
      return filterValues.map((value, index) => (
        <FilterTag
          key={`filter-tag-${index}`}
          filterValue={value}
          isDateRange={isDateRange}
          shouldDisplayFilterValueAsIs={shouldDisplayFilterValueAsIs}
          showFullSize={false}
          onClearFilter={
            shouldShowClearButton
              ? () => {
                  onClearFilter?.(index)
                }
              : () => {}
          }
        />
      ))
    }

    useEffect(() => {
      setIsOpen(!!isAccordionOpen)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAccordionOpen])

    const isTabletOrMobile = useIsTabletOrMobile()

    const hasFilterValue = !isEmpty(filterValue)

    const filters: FilterValue[] = hasMultipleValues
      ? [...(filterValue as FilterValue[])]
      : [filterValue]

    const renderClearButton = () =>
      shouldShowClearButton ? (
        <ClearButton
          disabled={!hasFilterValue || shouldDisableClearButton}
          onClick={event => {
            event.stopPropagation()
            onClearFilter?.()
          }}
        >
          Clear
        </ClearButton>
      ) : null

    const renderShowColumn = () =>
      onShowFilterAsColumn ? (
        <Tooltip content={showFilterAsColumn ? 'Hide column' : 'Show column'} placement="top">
          <div>
            <ShowColumnButton
              onClick={event => {
                event.stopPropagation()
                onShowFilterAsColumn?.()
              }}
            >
              {showFilterAsColumn ? <IconEye /> : <IconEyeBlocked />}
            </ShowColumnButton>
          </div>
        </Tooltip>
      ) : null

    const renderDesktopFilter = () =>
      !isSidebarFilter ? (
        <div className={className} ref={ref}>
          {children}
          {shouldShowClearButton && renderClearButton()}
          {renderShowColumn()}
        </div>
      ) : (
        <S.FilterContainer isOverflowing={isOverflowing}>
          <S.FilterHeader isOpen={isOpen} onClick={handleSetIsOpen}>
            <span>{title}</span>

            {!isInlineFilter ? (
              <S.FilterHeaderOptions>
                {isOpen && <Desktop>{renderClearButton()}</Desktop>}
                {renderShowColumn()}
              </S.FilterHeaderOptions>
            ) : null}

            {!hideFilterTag && hasFilterValue && !isOpen ? (
              <S.TagRow>{customFilterTag || renderFilterTags(filters)}</S.TagRow>
            ) : null}

            {isInlineFilter ? <S.FilterHeaderOptions>{children}</S.FilterHeaderOptions> : null}
          </S.FilterHeader>

          {!isInlineFilter && (
            <S.FilterOptions isOpen={isOpen}>{isOpen ? children : null}</S.FilterOptions>
          )}
          <Separator />
        </S.FilterContainer>
      )

    return isTabletOrMobile && !shouldOverwriteMobileStyle ? (
      <S.MobileWrapper ref={ref}>
        <S.FilterHeader onClick={handleSetIsOpen} isInlineFilter={isInlineFilter}>
          {title}

          {isInlineFilter ? <S.FilterHeaderOptions>{children}</S.FilterHeaderOptions> : null}
        </S.FilterHeader>
        {isOpen && !isInlineFilter ? children : null}
      </S.MobileWrapper>
    ) : (
      renderDesktopFilter()
    )
  },
)
