import React, { FC, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'

import AuthContext from '@contexts/Auth/AuthContext'

interface ChildrenParameters {
  signOut: () => void
  user?: any
  isSignedIn?: boolean
  location?: any
}

interface AuthorizedProps {
  renderAuthorizing?: FC
  renderUnauthorized?: any
  errorComponent?: FC
  children: (params: ChildrenParameters) => ReactNode
}

const Authorized = ({
  children,
  renderAuthorizing: RenderAuthorizing,
  renderUnauthorized: RenderUnauthorized,
  errorComponent: ErrorComponent,
}: AuthorizedProps) => {
  const location = useLocation()

  return (
    <AuthContext.Consumer>
      {({ isAuthorizing, isSignedIn, signIn, user, signOut }) => {
        if (RenderAuthorizing && isAuthorizing) {
          return <RenderAuthorizing />
        }

        if (RenderUnauthorized && !isSignedIn) {
          return <RenderUnauthorized signIn={signIn} />
        }

        if (!isAuthorizing && !user?.email && ErrorComponent) {
          return <ErrorComponent />
        }

        return children({ signOut, user, isSignedIn, location })
      }}
    </AuthContext.Consumer>
  )
}

export default Authorized
