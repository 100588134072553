import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { formatDate, TextField } from '@avantstay/backoffice-core'

// eslint-disable-next-line import/no-cycle
import { jsonParseCustomFieldValue } from '@utils/customFields'

import {
  CustomFieldProps,
  CustomFieldTypeReturn,
  IInputProps,
  IMountComponentProps,
} from '../_types'
import * as S from './types.styles'

const MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

const parseValueToShow = (customField: CustomFieldProps) => {
  const value = jsonParseCustomFieldValue(customField)
  if (isEmpty(value) || !value) return null

  const adjustedValue =
    typeof value === 'string' && /\d{2}\/\d{2}\/\d{4}/.test(value)
      ? `${value.slice(6)}-${value.slice(0, 2)}-${value.slice(3, 5)}`
      : value

  return formatDate(adjustedValue, 'MM/dd/yyyy')
}

const parseValueToSave = (customField: CustomFieldProps) => {
  const validString =
    customField.value !== 'null'
      ? JSON.stringify(formatDate(customField.value, 'yyyy-MM-dd'))
      : JSON.stringify(null)

  return typeof customField.value === 'string' ? validString : JSON.stringify(null)
}

function ComponentReadMode({ customField }: IMountComponentProps) {
  return <S.Text>{parseValueToShow(customField)}</S.Text>
}

function ComponentWriteMode({
  inputProps,
  error,
  warning,
  isReadOnly,
  customField,
  disabled,
}: IMountComponentProps) {
  if (isReadOnly) return <ComponentReadMode customField={customField} />

  return (
    <TextField.Masked
      {...(inputProps as IInputProps)}
      mask={MASK}
      error={error}
      warning={warning}
      disabled={disabled}
    />
  )
}

export default {
  read: { Component: ComponentReadMode },
  write: { Component: ComponentWriteMode },
  parseValueToShow,
  parseValueToSave,
} as CustomFieldTypeReturn
