import arriereBackoffice, { RoleInput } from '@avantstay/arriere-clients/dist/arriereBackoffice'

import {
  BackOfficeUsersParams,
  CreateOrUpdateUserProps,
} from '@new/domains/settings/screens/usersAndPermissions/UsersAndPermissions.types'

const userRolesFragment = {
  id: true,
  name: true,
  createdAt: true,
  updatedAt: true,
  privileges: {
    name: true,
    category: true,
    description: true,
    action: true,
  },
  groupedPrivileges: {
    privileges: {
      resource: true,
      actionKinds: {
        id: true,
        action: true,
      },
    },
  },
}

export const getBackofficeUsers = async ({ terms, includeInactive }: BackOfficeUsersParams) =>
  arriereBackoffice.queries.backOfficeUsers
    .raw({
      __alias: 'getBackofficeUsers',
      __args: {
        terms,
        includeInactive,
      },
      phone: true,
      backupPhone: true,
      email: true,
      active: true,
      lastAccessAt: true,
      lighthousePhone: true,
      onCallPhone: true,
      roles: {
        ...userRolesFragment,
      },
      spokePhone: true,
      ...userRolesFragment,
    })
    .then(({ data, errors }) => {
      if (errors?.length) {
        throw new Error(errors[errors?.length - 1]?.message)
      }

      return data
    })

export const getBackofficePrivileges = () => {
  return arriereBackoffice.queries.backOfficePrivileges({
    __alias: 'getBackofficePrivileges',
    name: true,
    category: true,
    description: true,
    action: true,
  })
}

export const getRoles = () => {
  return arriereBackoffice.queries.roles({
    __alias: 'getBackofficeRoles',
    ...userRolesFragment,
  })
}

export type BackOfficeUser = Awaited<ReturnType<typeof getBackofficeUsers>>[0]

export type BackOfficePrivilege = Awaited<ReturnType<typeof getBackofficePrivileges>>[0]

export type BackOfficeRole = Awaited<ReturnType<typeof getRoles>>[0]

export default {
  // Mutations
  async createOrUpdateBackOfficeUser({ email, name, privileges, roles }: CreateOrUpdateUserProps) {
    return arriereBackoffice.mutations.backOfficeUser({
      createOrUpdate: {
        __args: { email, name, privileges, roles },
        ...userRolesFragment,
        email: true,
        active: true,
        lastAccessAt: true,
        roles: {
          ...userRolesFragment,
        },
      },
    })
  },

  async activateUser(email: string) {
    return arriereBackoffice.mutations.backOfficeUser({
      activate: {
        __args: { email },
      },
    })
  },

  async deactivateUser(email: string) {
    return arriereBackoffice.mutations.backOfficeUser({
      deactivate: {
        __args: { email },
      },
    })
  },

  async deleteUser(email: string) {
    return arriereBackoffice.mutations.backOfficeUser({
      delete: {
        __args: { email },
      },
    })
  },

  async createOrUpdateBackOfficeRole(role: RoleInput) {
    return arriereBackoffice.mutations.backOfficeUser({
      createOrUpdateBackOfficeRole: {
        __args: {
          roles: [role],
        },
        id: true,
        name: true,
        createdAt: true,
        updatedAt: true,
      },
    })
  },
}
