import * as React from 'react'
import type { ComponentType, LazyExoticComponent } from 'react'

import { ErrorBoundary } from '@new/__global/components/ErrorBoundary'

type CompType = LazyExoticComponent<ComponentType> | ComponentType

export function withErrorBoundary(Component: CompType) {
  return (props: any) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  )
}
