import arriereBackoffice, { OwnerType } from '@avantstay/arriere-clients/dist/arriereBackoffice'
import { Alert } from '@avantstay/backoffice-core'

import {
  CreateOwnerProps,
  GetOwnerProps,
  InviteDataInputProps,
  UpdateOwnerProps,
} from '@/_new/domains/owners/screens/_types'

interface BulkCreateOwnerItem {
  address?: string
  email: string
  enableNotification: boolean
  fullName: string
  homeId: string
  ownerType: OwnerType
  phone?: string
  sendInvite: boolean
}

export default {
  async getOwnerByEmail(email: string) {
    return arriereBackoffice.queries
      .homeOwner({
        __alias: 'owners_getOwnerByEmail',
        getOwnerByEmail: {
          __args: { email },
          fullName: true,
          email: true,
          inviteSentAt: true,
          homeownerId: true,
          propertiesCount: true,
          phone: true,
          lastLoginAt: true,
          inviteAcceptedAt: true,
          ownerType: true,
          address: true,
          notificationEnabled: true,
        },
      })
      .then(res => res.getOwnerByEmail)
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async getOwners({ terms, showPendingInvites, pagination }: GetOwnerProps) {
    return arriereBackoffice.queries
      .homeOwner({
        __alias: 'owners_getOwners',
        getHomeOwners: {
          __args: { terms, showPendingInvites, pagination },
          total: true,
          pages: true,
          results: {
            fullName: true,
            email: true,
            inviteSentAt: true,
            homeownerId: true,
            propertiesCount: true,
            phone: true,
            lastLoginAt: true,
            inviteAcceptedAt: true,
            ownerType: true,
            address: true,
            notificationEnabled: true,
          },
        },
      })
      .then(res => res.getHomeOwners)
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async getOwnerProperties(ownerId: string) {
    return arriereBackoffice.queries
      .homeOwner({
        __alias: 'owners_getOwnerProperties',
        ownedHomesByOwnerId: {
          __args: { ownerId },
          id: true,
          title: true,
          thumbnail: { id: true, url: true },
          region: { name: true },
        },
      })
      .then(res => res.ownedHomesByOwnerId)
      .catch(error => {
        Alert.Error((error as Error).message)
        return undefined
      })
  },
  async requestPasswordReset(email: string) {
    return arriereBackoffice.mutations
      .homeOwner({
        __alias: 'owners_requestPasswordReset',
        requestPasswordReset: { __args: { email } },
      })
      .then(res => res.requestPasswordReset)
      .catch(error => {
        Alert.Error((error as Error).message)
        return false
      })
  },
  async deleteHomeOwner(ownerId: string) {
    return arriereBackoffice.mutations
      .homeOwner({
        __alias: 'owners_deleteHomeOwner',
        deleteHomeOwner: { __args: { ownerId } },
      })
      .then(res => res.deleteHomeOwner)
      .catch(error => {
        Alert.Error((error as Error).message)
        return false
      })
  },
  async updateAccount({ ownerId, details }: UpdateOwnerProps) {
    return arriereBackoffice.mutations
      .homeOwner({
        __alias: 'owners_updateAccount',
        updateAccount: {
          __args: { updateDataInput: { ownerId, details } },
        },
      })
      .then(res => res.updateAccount)
      .catch(error => {
        Alert.Error((error as Error).message)
        return false
      })
  },
  async createOwner({ createDataInput, sendInvite }: CreateOwnerProps) {
    return arriereBackoffice.mutations
      .homeOwner({
        __alias: 'owners_createOwner',
        createOwnerAndInviteIfNecessary: {
          __args: { createDataInput, sendInvite },
        },
      })
      .then(res => res.createOwnerAndInviteIfNecessary)
      .catch(error => {
        Alert.Error((error as Error).message)
        return false
      })
  },
  async createOwners(owners: BulkCreateOwnerItem[]): Promise<string[]> {
    const { errors } = await arriereBackoffice.mutations.homeOwner.raw({
      __alias: 'owners_createOwners',
      bulkCreateHomeOwners: { __args: { createDataInput: owners } },
    })

    if (errors?.length > 0) {
      return errors.map(error => error?.message).filter(error => error)
    }

    return []
  },
  async inviteOwner(inviteDataInput: InviteDataInputProps) {
    return arriereBackoffice.mutations
      .homeOwner({
        __alias: 'owners_inviteOwner',
        resendInviteToOwner: { __args: { inviteDataInput } },
      })
      .then(res => res.resendInviteToOwner)
      .catch(error => {
        throw new Error(error)
      })
  },
}
